// src/components/Signin.js

import React, { useState } from 'react';
import axios from 'axios';
import './SignIn.css';

const SignIn = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        if (isSignUp) {
          const response = await axios.post('http://cssk8x6952:4000/signup', formData);
          console.log('Signup response:', response.data);
          // Handle successful signup (e.g., navigate to login or dashboard page, show success message, etc.)
        } else {
          const response = await axios.post('http://cssk8x6952:4000/signin', formData);
          console.log('Signin response:', response.data);
        }
    } catch (error) {
        console.error('Error during signin/signup:', error);
        // Handle signup error (e.g., show error message to user)
    }
    console.log(formData);
  };

  return (
    <div className="signin-container">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Email</label>
          <input type="email" name="email" required value={formData.email} onChange={handleChange} />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input type="password" name="password" required value={formData.password} onChange={handleChange} />
        </div>
        {isSignUp && (
          <div className="input-group">
            <label>Confirm Password</label>
            <input type="password" name="confirmPassword" required />
          </div>
        )}
        {isSignUp && (
          <div className="input-group">
            <label>Username</label>
            <input type="text" name="username" required value={formData.username} onChange={handleChange} />
          </div>
        )}
        <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
      </form>
      {isSignUp ? (
        <p>
          Already have an account?{' '}
          <button className="switch-btn" onClick={() => setIsSignUp(false)}>
            Sign In
          </button>
        </p>
      ) : (
        <p>
          Don't have an account?{' '}
          <button className="switch-btn" onClick={() => setIsSignUp(true)}>
            Sign Up
          </button>
        </p>
      )}
    </div>
  );
};

export default SignIn;