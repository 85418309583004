import logo from './logo.png';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import SignIn from './components/SignIn';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={
          <div className="App">
            <header className="App-header">
              <Navbar />
              <img src={logo} className="App-logo" alt="logo" />
              <SignIn />
            </header>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;
